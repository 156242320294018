<template>
    <aside
        :class="['hidden-print-only', {'trans': !input.isCompare && !sidebarOnMove,}, 'aside-info-wrapper', {open}]"
    >
        <div
            id="sidebar"
            :class="[{'aside-info--banner-visible':banner,'trans':!sidebarOnMove,...mainHeaderClasses,}, 'aside-info']"
        >
            <div
                class="aside-info-pullbar"
            >
                <v-btn
                    :aria-pressed="pinned?'true':'false'"
                    :title="messagesLoaded ? t(`async.sidebar.${pinned ? 'unpin_btn_label':'pin_btn_label'}`) : ''"
                    :aria-label="messagesLoaded ? t(`async.sidebar.${pinned ? 'unpin_btn_label':'pin_btn_label'}`) : ''"
                    variant="text"
                    :color="loggedIn ? 'white' : 'secondary'"
                    :class="['pinicon', 'hidden-sm-and-down', {pinned,}]"
                    @click="togglePinned"
                >
                    <v-icon>
                        bs:$vuetify.icons.mdiPin
                    </v-icon>
                </v-btn>
                <v-btn
                    v-if="isTextView && !open"
                    :title="messagesLoaded ? t('async.sidebar.verse_ref', {textref:verseTextRef,}) : ''"
                    :aria-label="messagesLoaded ? t('async.sidebar.verse_ref', {textref:verseTextRef,}) : ''"
                    variant="text"
                    color="white"
                    :class="['hidden-sm-and-down']"
                    :to="{path:versePath}"
                >
                    <v-icon>
                        bs:$vuetify.icons.mdiEye
                    </v-icon>
                </v-btn>
                <like-button
                    v-if="isTextView && !open"
                    class="hidden-sm-and-down"
                    :icon="false"
                    :messages-loaded="messagesLoaded"
                    :tooltip-disabled="true"
                />
                <v-btn
                    v-if="isTextView && !open"
                    :title="messagesLoaded ? t('async.tag.create_tag') : ''"
                    :aria-label="messagesLoaded ? t('async.tag.create_tag') : ''"
                    variant="text"
                    :color="loggedIn ? 'white' : 'secondary'"
                    class="hidden-sm-and-down"
                    @click="handleTagAction"
                >
                    <v-icon>
                        bs:$vuetify.icons.mdiTag
                    </v-icon>
                </v-btn>
                <v-btn
                    v-if="isTextView && !open"
                    :title="messagesLoaded ? t('async.nicodemus-ai.new_chat') : ''"
                    :aria-label="messagesLoaded ? t('async.nicodemus-ai.new_chat') : ''"
                    variant="text"
                    :color="loggedIn ? 'white' : 'secondary'"
                    class="hidden-sm-and-down"
                    @click="handleChatAction"
                >
                    <v-icon>
                        bs:$vuetify.icons.mdiChatPlus
                    </v-icon>
                </v-btn>
                <v-btn
                    v-if="isTextView && !open"
                    :title="messagesLoaded ? t('async.note.new_title') : ''"
                    :aria-label="messagesLoaded ? t('async.note.new_title') : ''"
                    variant="text"
                    :color="loggedIn ? 'white' : 'secondary'"
                    class="hidden-sm-and-down"
                    @click="handleNoteAction"
                >
                    <v-icon>
                        bs:$vuetify.icons.mdiPencil
                    </v-icon>
                </v-btn>
                <v-divider
                    v-if="!open"
                ></v-divider>
                <v-btn
                    :aria-pressed="open?'true':'false'"
                    :title="messagesLoaded ? t(`async.common.button.${open ? 'close_it':'open_it'}`, {object: t('async.sidebar.label')}) : ''"
                    :aria-label="messagesLoaded ? t(`async.common.button.${open ? 'close_it':'open_it'}`, {object: t('async.sidebar.label')}) : ''"
                    variant="text"
                    color="white"
                    :class="['pullicon',{'animate':!open && openAnimation,}]"
                >
                    <v-icon>
                        {{ !open && !sidebarOnMove ? 'bs:$vuetify.icons.mdiChevronLeft' : 'bs:$vuetify.icons.mdiUnfoldMoreVertical' }}
                    </v-icon>
                </v-btn>
                <v-divider
                    v-if="!open"
                ></v-divider>
                <v-no-ssr>
                    <button-group-share
                        v-if="!open"
                        class="hidden-sm-and-down"
                        :share-link="input.shareLink"
                        :messages-loaded="messagesLoaded"
                        :fab="false"
                    ></button-group-share>
                </v-no-ssr>
                <v-btn
                    v-if="!open"
                    variant="text"
                    class="hidden-sm-and-down"
                    color="white"
                    :title="messagesLoaded ? t('async.common.button.print') : ''"
                    :aria-label="messagesLoaded ? t('async.common.button.print') : ''"
                    :disabled="printBtnDisabled"
                    @click="doPrint"
                >
                    <v-icon>bs:$vuetify.icons.mdiPrinter</v-icon>
                </v-btn>
                <v-btn
                    v-if="!open"
                    id="help-btn"
                    class="hidden-sm-and-down"
                    :to="{path: '/help'}"
                    :title="messagesLoaded ? t('async.menu.help') : ''"
                    :aria-label="messagesLoaded ? t('async.menu.help') : ''"
                    variant="text"
                    color="white"
                >
                    <v-icon>bs:$vuetify.icons.mdiHelpCircle</v-icon>
                </v-btn>
            </div>

            <v-no-ssr>
                <div
                    v-if="messagesLoaded"
                    class="aside-info-content"
                    :aria-hidden="!open ? 'true' : 'false'"
                >
                    <sidebar-boxes
                        :messages-loaded="messagesLoaded"
                        :verse-path="versePath"
                        :verse-text-ref="verseTextRef"
                    />
                </div>
            </v-no-ssr>
        </div>

        <toolbar-tag-action
            v-if="tagAction"
            :messages-loaded="messagesLoaded"
            @close="tagAction = false"
        ></toolbar-tag-action>

        <toolbar-note-action
            v-if="noteAction"
            :messages-loaded="messagesLoaded"
            @close="noteAction = false"
        ></toolbar-note-action>
    </aside>
</template>

<script>
import ButtonGroupShare from '@/assets/js/src/components/ButtonGroupShare.vue'
import {useAutocompleteStore,} from '@/assets/js/src/modules/autocomplete/_pinia/autocomplete'
import {useBibleStore,} from '@/assets/js/src/modules/bible/_pinia/bible'
import {useLangStore,} from '@/assets/js/src/modules/lang/_pinia/lang'
import LikeButton from '@/assets/js/src/modules/sidebar/_components/LikeButton.vue'
import {INPUT_TYPE_SEARCH, INPUT_TYPE_TEXT,} from '@/assets/js/src/modules/sidebar/_pinia/_constants'
import {useSidebarStore,} from '@/assets/js/src/modules/sidebar/_pinia/sidebar'
import {useShowRegisterMessage,} from '@/assets/js/src/modules/snackbar/_composables/useShowRegisterMessage'
import {useTextStore,} from '@/assets/js/src/modules/text/_pinia/text'
import {useUserOptions,} from '@/assets/js/src/modules/user/_composables/useUserOptions'
import {mapUserOptionByPath,} from '@/assets/js/src/modules/user/_pinia/mapUserOptionByPath'
import {useUserStore,} from '@/assets/js/src/modules/user/_pinia/user'
import {useAppUiStore,} from '@/assets/js/src/pinia/appUi'
import xlVars from '@/assets/js/src/style/json/variables'
import mdVars from '@/assets/js/src/style/json/variables-md'
import xsVars from '@/assets/js/src/style/json/variables-xs'
import {useBibleRefs,} from '@/assets/js/src/util/composables/useBibleRefs'
import {useMainHeaderClasses,} from '@/assets/js/src/util/composables/useMainHeaderClasses'
import {usePrint,} from '@/assets/js/src/util/composables/usePrint'
import {getActivePinia, mapActions, mapState, mapWritableState,} from 'pinia'
import {computed, defineAsyncComponent, getCurrentInstance, watch,} from 'vue'
import {useRoute,} from 'vue-router'
import {throttle,} from "@/assets/js/src/util/uiTools"
import {useTranslation,} from '@/assets/js/src/util/composables/useTranslation'
import {useChatItemStore} from "@/assets/js/src/modules/chat/_pinia/chatItem"

export default {
    name: 'Sidebar',
    components: {
        LikeButton,
        ButtonGroupShare,
        ToolbarTagAction: defineAsyncComponent(() => import ('@/assets/js/src/modules/sidebar/_components/helper/ToolbarTagAction.vue')),
        ToolbarNoteAction: defineAsyncComponent(() => import ('@/assets/js/src/modules/sidebar/_components/helper/ToolbarNoteAction.vue')),
        SidebarBoxes: defineAsyncComponent(() => import ('@/assets/js/src/modules/sidebar/_components/SidebarBoxes.vue')),
    },
    props: {
        input: {
            type: Object,
            default: () => ({}),
        },
        messagesLoaded: {
            type: Boolean,
            default: false,
        },
    },
    setup () {
        let activePinia = getActivePinia()
        let appUiStore = useAppUiStore(activePinia)
        let sidebarStore = useSidebarStore(activePinia)
        let textStore = useTextStore(activePinia)
        let {proxy,} = getCurrentInstance()
        let {getUserOption,} = useUserOptions()
        watch(() => appUiStore.sidebarState.reload, (newValue) => {
            if (newValue) {
                let {mode, provider,} = appUiStore.sidebarState
                // Inhalt für die Sidebar vom bestimmten Provider aktualisieren, wo Inhalt sich durch create/update/delete verändert hat
                sidebarStore.fetchContentFromOneProvider({
                    type: proxy.input.type,
                    data: proxy.input.data,
                    provider,
                    mode, // Flag zum refreshen des Server-Caches
                }).then(() => {
                    appUiStore.resetSidebarState()
                })

                // Wenn Notizen gedruckt werden sollen, dann die vorab laden
                if (provider === 'note'
                && proxy.loggedIn
                && getUserOption('textFormats').printMyNotes
                ) {
                    textStore.fetchNotesByChapter({
                        chapterCanonical: proxy.input.data,
                    })
                }
            }
        })

        //
        const route = useRoute()
        const type = computed(() => route.meta.uiType === 'TextView' ? INPUT_TYPE_TEXT : INPUT_TYPE_SEARCH)

        return {
            ...useBibleRefs(),
            ...useMainHeaderClasses(),
            ...useShowRegisterMessage(),
            ...usePrint(),
            ...useTranslation(),
            getUserOption,
            type,
            pinned: computed(mapUserOptionByPath(`sidebar/${type.value}/pinned`)),
            open: computed(mapUserOptionByPath('sidebar/open')),
            openPos: computed(mapUserOptionByPath('sidebar/position')),
            openPosTop: computed(mapUserOptionByPath('sidebar/positionTop')),
            openPosDesktop: computed(mapUserOptionByPath('sidebar/positionDesktop')),
        }
    },
    data: () => ({
        openAnimation: false,
        tagAction: false,
        noteAction: false,
    }),
    computed: {
        ...mapState(useBibleStore, [
            'selectedBibles',
        ]),
        ...mapWritableState(useAppUiStore, [
            'sidebarOpen',
            'sidebarOpenWidth',
            'blockHeadroom',
            'sidebarOnMove',
        ]),
        ...mapWritableState(useSidebarStore, {
            sidebarInput: 'input',
        }),
        ...mapWritableState(useChatItemStore, {
            showChat: 'active',
            chatCanonicals: 'canonicals',
        }),
        ...mapState(useLangStore, [
            'locale',
        ]),
        ...mapState(useAppUiStore, [
            'banner',
            'printBtnDisabled',
            'helpTourRunning',
            'overlay',
            'menu',
        ]),
        ...mapState(useUserStore, [
            'loggedIn',
        ]),
        ...mapState(useAutocompleteStore, [
            'isComponentVisible',
        ]),
        boxes: function () {
            return Object.values(this.getUserOption(`sidebar/${this.createdType || this.type}/boxes`))
        },
        isTextView: function () {
            return this.$route.meta.uiType === 'TextView'
        },
        versePath: function () {
            if (!this.isTextView || !this.input.data) {
                return ''
            }
            let canonical = this.input.filter.length ? this.input.filter[0] : this.input.data + 1
            let textRef = this.getRefByCanonicals({canonicals: [ canonical, ], isUrl: true,})

            return textRef ? `/${this.locale}/verse/${textRef}` : ''
        },
        verseTextRef: function () {
            if (!this.isTextView || !this.input.data) {
                return ''
            }
            let canonical = this.input.filter.length ? this.input.filter[0] : this.input.data + 1

            return this.getRefByCanonicals({canonicals: [ canonical, ], type: this.REF_SHORT,})
        },
    },
    watch: {
        open: function (newValue, oldValue) {
            this.sidebarOpen = newValue
            // Beim Öffnen der Seitenleiste Inhalte laden
            if (newValue) {
                this.fetchSidebarContent({type: this.input.type, data: this.input.data,})
            } else {
                this.openAnimation = false
            }

            if (newValue !== oldValue) {
                if (!import.meta.env.SSR && typeof this.scrollElems[this.scrollTo.index] !== 'undefined') {

                    this.$nextTick(() => {
                        let scrollTo = this.oldPos + (this.scrollElems[this.scrollTo.index].offsetTop - this.scrollTo.elemTop)
                        window.scrollTo(0, scrollTo)
                    })
                    setTimeout(() => {
                        this.blockHeadroom = false
                    }, 300)
                }

                // Für die HelpTour spezifisch öffnen
                if (!import.meta.env.SSR && this.$vuetify.display.smAndDown && this.helpTourRunning) {
                    let {applyPos, resetPos,} = this.initHammerMethodsXs()
                    if (newValue) {
                        applyPos({})
                    } else {
                        resetPos()
                    }
                }
            }
        },

        helpTourRunning (newValue, oldValue) {
            if (newValue !== oldValue && newValue && this.$vuetify.display.smAndDown && this.open) {
                let {applyPos, resetPos,} = this.initHammerMethodsXs()
                if (newValue) {
                    applyPos({})
                } else {
                    resetPos()
                }
            }
        },
        openPosTop: {
            handler (newValue, oldValue) {
                if (this.$vuetify.display.smAndDown) {
                    if (newValue !== oldValue && newValue && this.open) {
                        this.blockHeadroom = true
                    } else {
                        this.blockHeadroom = false
                    }
                }
            },
            immediate: !import.meta.env.SSR,
        },
        input: {
            handler: function (newValue, oldValue) {
                let newJson = JSON.stringify(newValue)
                if (!this._inactive && (newJson !== JSON.stringify(oldValue) || newJson !== JSON.stringify(this.sidebarInput))) {
                    // Wenn Verse ausgewählt sind, dann die Sidebar auf der Client-Seite öffnen
                    if (!import.meta.env.SSR) {
                        let schouldDo = false
                        if (typeof oldValue === 'undefined') { // bei window reload (F5)
                            schouldDo = true
                        } else {
                            if ((this.createdType || this.type) === INPUT_TYPE_TEXT) {
                                schouldDo = oldValue.filter.length === 0
                            } else if ((this.createdType || this.type) === INPUT_TYPE_SEARCH) {
                                schouldDo = newValue.filter !== oldValue.filter
                            }
                        }
                        if (typeof newValue.filter !== 'undefined' && newValue.filter.length !== 0 && schouldDo) {
                            // im $nextTick damit auch die es bei window reload (F5) funktioniert
                            this.$nextTick(() => {
                                if (!this.$vuetify.display.smAndDown) {
                                    this.calculateScrollElems()
                                    this.open = this.open || !this.pinned
                                } else {
                                    this.openAnimation = true
                                }
                            })
                        } else if (typeof newValue.filter !== 'undefined' && newValue.filter.length === 0) {
                            this.openAnimation = false
                        }
                    }

                    // Inhalt im Store setzen für die einzelnen Boxes
                    if (
                        (((this.createdType || this.type) === INPUT_TYPE_TEXT) && typeof newValue.data === 'number' && newValue.data > 1000000) ||
                  (((this.createdType || this.type) === INPUT_TYPE_SEARCH) && typeof newValue.data === 'string')
                    ) {
                        this.sidebarInput = newValue
                        if (newValue.data !== oldValue?.data && !import.meta.env.SSR && this.open) {
                            // Inhalt für die Sidebar vom Server laden
                            this.fetchSidebarContent({
                                type: newValue.type,
                                data: newValue.data,
                            })
                        }
                    }
                }
            },
            immediate: true,
        },
        $route: {
            handler: function (to) {
                // Da die Sidebar eingebunden ist in Keep-Alive-Komponenten die Aktionen nur mit folgenden Bedingungen ausführen
                if (to.meta.sidebar && !this._inactive) {
                    // Inhalt für die Sidebar  vom Server laden
                    if (this.open && this.input.filter.length === 0) {
                        if (import.meta.env.SSR && !this.pinned) {
                            this.open = false
                        } else if (!this.$vuetify.display.smAndDown && !this.pinned && !import.meta.env.SSR) {
                            this.open = false
                        } else if (this.$vuetify.display.smAndDown && this.openPosTop && !import.meta.env.SSR) {
                            this.open = false
                            let revElems = Array.from(document.querySelectorAll('.aside-info-wrapper,.text-nav.back,.text-nav.forward,.bs-app__scroll-top-btn'))
                            revElems.forEach((elem) => {
                                elem.style.bottom = null
                            })
                        }
                    }

                    if (!import.meta.env.SSR && this.$vuetify.display.smAndDown) {
                        setTimeout(() => {
                            let {applyPos, resetPos,} = this.initHammerMethodsXs()
                            if (this.open) {
                                applyPos({})
                            } else {
                                resetPos()
                            }
                        },500)
                    }
                }
            },
            immediate: true,
        },
        loggedIn: function () {
            this.fetchSidebarContent({
                type: this.input.type,
                data: this.input.data,
                force: true,
            })
        },
        locale: function () {
            this.fetchSidebarContent({
                type: this.input.type,
                data: this.input.data,
                force: true,
            })
        },
        '$vuetify.display.mdAndUp': {
            handler: function () {
                let {applyPos,} = this.initHammerMethodsXl()
                let minWidth = this.$vuetify.display.width >= this.$vuetify.display.thresholds.md ?
                    parseInt(xlVars.sidebar.open.width) :
                    parseInt(mdVars.sidebar.open.width)
                applyPos({width: Math.max(minWidth, this.openPosDesktop ?? 0),})
            },
            immediate: !import.meta.env.SSR,
        },
    },
    created () {
        this.createdType = this.type
        this.textType = INPUT_TYPE_TEXT
        this.oldPos = 0
        this.scrollElems = []
        this.scrollTo = {index: 0, elemTop: 0,}
        this.hammer = null
    },
    mounted () {

        if (this.$vuetify.display.smAndDown) {
            this.blockHeadroom = false
            this.open = false
        }

        // Wenn nicht angemeldet und in der Search-Ansicht (Desktop), dann seitbar immer öffnen
        if (!this.loggedIn && this.createdType === INPUT_TYPE_SEARCH && !this.$vuetify.display.smAndDown) {
            this.open = true
        }

        document.addEventListener('keyup', this.handleKeyUp)

        ;(async ()=>{
            let selector = '.aside-info-pullbar'

            const {default: Hammer,} = await import('hammerjs')

            let times = 0
            let pullbar = null

            const interval = setInterval(() => {
                times += 1
                if (pullbar || times === 20) {
                    clearInterval(interval)
                    if (pullbar) {
                        const hammer = new Hammer(pullbar)

                        if (this.$vuetify.display.smAndDown) {
                            hammer.get('pan').set({direction: Hammer.DIRECTION_VERTICAL,})

                            let {panListener,} = this.initHammerMethodsXs()

                            // listen to events...
                            hammer.on('panup pandown tap panstart panend', panListener)
                        } else {
                            hammer.get('pan').set({direction: Hammer.DIRECTION_HORIZONTAL,})

                            let {panListener,} = this.initHammerMethodsXl()

                            // listen to events...
                            hammer.on('panleft panright tap panstart panend press pressup', panListener)
                        }

                        this.hammer = hammer
                    }
                }
                pullbar = document.querySelector(selector)
            }, 100)
        })()

        window.addEventListener('resize', this.resizeHandler)
    },
    beforeUnmount () {
        window.removeEventListener('resize', this.resizeHandler)
        if (this.hammer) {
            this.hammer.destroy()
            this.hammer = null
        }
    },
    methods: {
        ...mapActions(useSidebarStore, [
            'fetchSidebarContent',
        ]),
        handleKeyUp (event) {
            if (!this._inactive && !this.overlay && !this.menu && !this.isComponentVisible && event.target.tagName !== 'INPUT' && event.target.tagName !== 'TEXTAREA' && !event.target.classList.contains('ck-content')) {
                /** i - Taste **/
                if (event.keyCode === 73) {
                    this.open = !this.open
                    this.$bsa && this.$bsa.event({
                        eventCategory: 'shortcuts',
                        eventAction: `i (Seitenleiste ${this.open ? 'öffnen' : 'schließen'})`,
                    })
                }
            }
        },
        // Finde die Position des DOM-ELems zu dem gescrollt werden soll
        getScrollingPosition (elem, divider) {
            if (!this.scrollElems[elem]) {
                return
            }
            let newElem = elem - divider

            if (elem < 0) {
                elem = 0
            }

            let elemTop = this.scrollElems[elem].offsetTop

            if (elemTop < this.oldPos) {
                newElem = elem + divider
            }

            if (divider > 1 && !(this.oldPos < elemTop && (elem > 0 && this.oldPos > this.scrollElems[elem - 1].offsetTop))) {
                this.getScrollingPosition(newElem, Math.ceil(divider / 2))
            } else {
                if (this.oldPos > elemTop) {
                    this.scrollTo = {
                        index: elem + 1,
                        elemTop: (this.scrollElems[elem + 1] || this.scrollElems[elem]).offsetTop,
                    }
                } else {
                    this.scrollTo = {index: elem, elemTop: elemTop,}
                }
            }
        },
        calculateScrollElems () {
            if (!import.meta.env.SSR) {
                this.scrollElems = (document.getElementById('chapter-wrapper') || document).querySelectorAll('article:first-of-type .scroll-elem')
                if (this.scrollElems.length <= 1) {
                    for (let i = 2; i <= this.selectedBibles.length; i++) {
                        let scrollElemsTmp = (document.getElementById('chapter-wrapper') || document).querySelectorAll('article:nth-of-type(' + i + ') .scroll-elem')
                        if (scrollElemsTmp.length > this.scrollElems.length) {
                            this.scrollElems = scrollElemsTmp
                        }
                    }
                }

                this.oldPos = window.pageYOffset
                this.getScrollingPosition(Math.ceil((this.scrollElems.length - 1) / 2), Math.ceil((this.scrollElems.length - 1) / 4))
            }
        },
        initHammerMethodsXl () {
            let width = this.sidebarOpenWidth

            let viewportWidth = Math.max(document.documentElement.clientWidth, window.innerWidth || 0)
            let getMaxWidth = () => parseInt(viewportWidth / 25 * 12)
            let getMinWidth = () => this.$vuetify.display.mdAndDown ?
                parseInt(mdVars.sidebar.open.width) :
                parseInt(xlVars.sidebar.open.width)
            let maxWidth = getMaxWidth()
            let minWidth = getMinWidth()
            let viewportWidthFn = () => Math.max(document.documentElement.clientWidth, window.innerWidth || 0)

            // Funktion, um die Sidebarhöhe anzuwenden
            let applyPos = throttle(({width,}) => {
                if (viewportWidth !== viewportWidthFn()) {
                    viewportWidth = viewportWidthFn()
                    maxWidth = getMaxWidth()
                    minWidth = getMinWidth()
                }
                width = parseInt(width) || maxWidth


                // Min-Limit
                if (width <= minWidth) {
                    width = minWidth
                }

                // Max-Limit
                if (width > maxWidth) {
                    width = `min(48vw,${maxWidth}px)`
                }

                this.sidebarOpenWidth = width
            },20)

            let aside = document.querySelector('.aside-info-wrapper')
            if (!aside) {
                return {
                    panListener: () => {
                    },
                    applyPos,
                }
            }

            return {
                panListener: (ev) => {
                    if (ev.type === 'panstart') {
                        if (!this.open) {
                            this.open = true
                        }
                        width = aside.getBoundingClientRect().width
                    }
                    if (ev.type === 'panleft' || ev.type === 'panright') {
                        applyPos({width:width - ev.deltaX,})
                        this.sidebarOnMove = true
                    } else {
                        this.sidebarOnMove = ev.type === 'press'
                    }
                    if (ev.type === 'panend') {
                        // fix sidebar and save pos in user options
                        let diff = width - ev.deltaX
                        applyPos({width:diff,})
                        this.openPosDesktop = diff
                    }
                    if (ev.type === 'tap') {
                        let {target,} = ev
                        while (target && target.tagName !== 'BUTTON') target = target.parentNode
                        if(!target?.classList?.contains('pullicon')) {
                            return
                        }

                        if (this.open) {
                            this.open = false
                        } else {
                            this.open = true
                            applyPos({width: this.sidebarOpenWidth,})
                        }
                    }
                },
                applyPos,
            }
        },
        initHammerMethodsXs () {
            let aside = document.querySelector('.aside-info-wrapper')
            if (!aside) {
                return {
                    panListener: () => {
                    },
                    applyPos: () => {
                    },
                    resetPos: () => {
                    },
                }
            }
            let asideContent = aside.querySelector('.aside-info-content')
            let closedSidebarHeight = parseInt(xsVars.sidebar.closed.height)
            let dropTolerance = parseInt(xsVars.sidebar.dropTolerance)

            let chatBtn = document.getElementById('chat-btn')
            let textnavleft = document.querySelector('.text-nav.back')
            let textnavright = document.querySelector('.text-nav.forward')
            let textNavPadding = parseInt(xlVars.text.textNav.padding)
            let textNavOffset = parseInt(xlVars.text.textNav.offset)

            let scrolltopbtn = document.querySelector('.bs-app__scroll-top-btn')
            let mainHeader = document.querySelector('.bs-header-wrapper')
            let viewportHeight = Math.max(document.documentElement.clientHeight, window.innerHeight || 0)
            let offset = 0
            let topOffset = 0
            let viewportHeightFn = () => Math.max(document.documentElement.clientHeight, window.innerHeight || 0)

            // Funktion, um die Sidebarhöhe anzuwenden
            let applyPos = ({top,}) => {
                top = top || (viewportHeight / 3 * 2)

                // Max-Limit
                if (topOffset === 0 || viewportHeight !== viewportHeightFn()) {
                    viewportHeight = viewportHeightFn()
                    topOffset = getTopOffset()
                }
                if (top <= topOffset) {
                    top = topOffset
                }

                // Min-Limit
                if (top > viewportHeight - closedSidebarHeight) {
                    top = viewportHeight - closedSidebarHeight
                }

                //
                if (aside) {
                    aside.style.top = (top / viewportHeight) * 100 + '%'
                }
                if (asideContent) {
                    asideContent.style.height = (viewportHeight - top - closedSidebarHeight) / viewportHeight * 100 + '%'
                }

                // Text-Nav in Text-View berücksichtigen
                if (textnavleft && textnavright) {
                    textnavleft.style.bottom = (viewportHeight - top + textNavPadding) / viewportHeight * 100 + '%'
                    textnavright.style.bottom = (viewportHeight - top + textNavPadding) / viewportHeight * 100 + '%'
                }

                if (chatBtn) {
                    if (textnavleft && textnavright) {
                        chatBtn.style.bottom = (viewportHeight - top + textNavPadding + textNavOffset) / viewportHeight * 100 + '%'
                    } else {
                        chatBtn.style.bottom = (viewportHeight - top + textNavPadding) / viewportHeight * 100 + '%'
                    }
                }

                // ScrollTop in Search-View berücksichtigen
                if (scrolltopbtn) {
                    scrolltopbtn.style.bottom = (viewportHeight - top + textNavPadding) / viewportHeight * 100 + '%'
                }
            }

            // Funktion, um die Sidebarhöhe anzuwenden
            let resetPos = () => {

                if (aside) {
                    aside.style.bottom = null
                }

                if (asideContent) {
                    asideContent.style.height = null
                }

                // Text-Nav in Text-View berücksichtigen
                if (textnavleft && textnavright) {
                    textnavleft.style.bottom = null
                    textnavright.style.bottom = null
                }

                if (chatBtn) {
                    if (textnavleft && textnavright) {
                        chatBtn.style.bottom = (closedSidebarHeight + textNavPadding + textNavOffset) / viewportHeight * 100 + '%'
                    } else {
                        chatBtn.style.bottom = (closedSidebarHeight + textNavPadding) / viewportHeight * 100 + '%'
                    }
                }

                // ScrollTop in Search-View berücksichtigen
                if (scrolltopbtn) {
                    scrolltopbtn.style.bottom = null
                }
            }

            let adjustPadding = ({reset, padding,}) => {
                let articleWrapper = document.querySelector('#chapter-wrapper')
                if (!articleWrapper) {
                    articleWrapper = document.querySelector('.article-wrapper')
                }
                if (!articleWrapper) {
                    return
                }
                let articles = articleWrapper.querySelectorAll('article')
                reset = reset || false
                padding = padding || 0
                articles.forEach((article) => {
                    let footer = article.querySelector('footer')
                    if (article.parentElement.classList.contains('textformat--oneverseperline')) {
                        if (footer) {
                            if (reset) {
                                footer.style.marginBottom = null
                            } else {
                                article.style.paddingBottom = null
                                footer.style.marginBottom = `calc(${viewportHeight - padding}px + 3em)`
                            }
                        }
                    } else {
                        if (reset) {
                            article.style.paddingBottom = null
                        } else {
                            if (footer) {
                                footer.style.marginBottom = null
                            }
                            article.style.paddingBottom = `calc(${viewportHeight - padding}px + 3em)`
                        }
                    }
                })
            }

            let getTopOffset = () => {
                if (window.MAIN_HEADROOM) {
                    window.MAIN_HEADROOM.top()
                    window.MAIN_HEADROOM.pin()
                }
                let topOffset = mainHeader.clientHeight
                if (this.banner) {
                    topOffset += document.querySelector('.banner-wrapper__banner')?.clientHeight ?? 0
                }

                return topOffset
            }

            let dropZones = () => {
                return {
                    top: dropTolerance >= aside.getBoundingClientRect().top - getTopOffset(),
                    bottom: (dropTolerance + closedSidebarHeight) >= (viewportHeightFn() - aside.getBoundingClientRect().top),
                }
            }

            let panListener = (ev) => {
                if (!asideContent) {
                    asideContent = aside.querySelector('.aside-info-content')
                }
                if (ev.type === 'panstart') {
                    if (!this.open) {
                        this.open = true
                        this.$bsa && this.$bsa.event({
                            eventCategory: 'button actions',
                            eventAction: 'Seitenleiste öffnen',
                        })
                    }
                    offset = aside.getBoundingClientRect().top
                }
                if (ev.type === 'panup' || ev.type === 'pandown') {
                    this.sidebarOnMove = true
                    applyPos({top: (ev.deltaY + offset),})
                } else {
                    this.sidebarOnMove = false
                }
                if (ev.type === 'panend') {
                    let {top, bottom,} = dropZones()
                    if (bottom) {
                        // close sidebar
                        this.open = false
                        this.$bsa && this.$bsa.event({
                            eventCategory: 'button actions',
                            eventAction: 'Seitenleiste schließen',
                        })
                        applyPos({top: viewportHeightFn() - closedSidebarHeight,})
                        adjustPadding({reset: true,})
                    } else if (top) {
                        // fix sidebar on top
                        topOffset = getTopOffset()
                        applyPos({top: topOffset,})
                        adjustPadding({reset: true,})
                        this.openPos = topOffset
                        this.openPosTop = true
                    } else {
                        // fix sidebar and save pos in user options
                        this.openPos = (ev.deltaY + offset)
                        this.openPosTop = false
                        adjustPadding({padding: this.openPos,})
                    }
                }
                if (ev.type === 'tap') {
                    if (this.open) {
                        this.blockHeadroom = false
                        this.open = false
                        this.$bsa && this.$bsa.event({
                            eventCategory: 'button actions',
                            eventAction: 'Seitenleiste schließen',
                        })
                        applyPos({top: viewportHeightFn() - closedSidebarHeight,})
                        adjustPadding({reset: true,})
                    } else {
                        // closed -> move to in user options saved position
                        this.open = true
                        this.$bsa && this.$bsa.event({
                            eventCategory: 'button actions',
                            eventAction: 'Seitenleiste öffnen',
                        })

                        if (this.openPosTop) {
                            this.blockHeadroom = true
                            getTopOffset()
                        }
                        applyPos({top: this.openPos,})
                        adjustPadding({padding: this.openPos,})
                    }
                }
            }

            return {
                panListener,
                applyPos,
                resetPos,
            }
        },
        resizeHandler () {
            if (this.open && this.openPosTop && this.$vuetify.display.smAndDown) {
                let {applyPos,} = this.initHammerMethodsXs()
                applyPos({top: this.openPos,})
            }
        },
        togglePinned () {
            if (!this.loggedIn) {
                this.showRegisterMessage({
                    messageKey: 'async.sidebar.pin_btn_login_notice',
                    placeholder: '{loginAction}',
                })

                return
            }
            this.pinned = !this.pinned
        },
        handleTagAction () {
            if (!this.loggedIn) {
                this.showRegisterMessage({
                    messageKey: 'async.tag.login_notice',
                    placeholder: '{loginAction}',
                })

                return
            }
            this.tagAction = true
        },
        handleChatAction () {
            if (!this.loggedIn) {
                this.showRegisterMessage({
                    messageKey: 'async.sidebar.chat.login_notice',
                    placeholder: '{loginAction}',
                })

                return
            }
            this.chatCanonicals = this.sidebarInput.filter.length
                ? this.sidebarInput.filter
                : [ this.sidebarInput.data, ]
            this.showChat = true
        },
        handleNoteAction () {
            if (!this.loggedIn) {
                this.showRegisterMessage({
                    messageKey: 'async.sidebar.notes.login_notice',
                    placeholder: '{loginAction}',
                })

                return
            }
            this.noteAction = true
        },
    },
}
</script>

<style lang="scss">
.bs-app {
    .aside-info-wrapper {
        position: relative;
        z-index: 9;
        flex-shrink: 0;
        width: #{map-deep-get($bs-xl,sidebar,closed,width )}px;
        height: 100%;
        background-color: map-deep-get($bs-color, sidebar, background);

        @media screen and (aspect-ratio >= 13 / 9) and (max-width: #{map-deep-get($bs-xs, breakpointValue)}px) {
            display: none;
        }

        .aside-info {
            position: fixed;
            top: 0;
            display: flex;
            height: 100%;
            padding-top: map-deep-get($bs-xl, header, height);

            @media (max-width: #{map-deep-get($bs-md, breakpointValue)}px) {
                padding-top: map-deep-get($bs-md, header, height);
            }

            &-pullbar {
                display: flex;
                flex-direction: column;
                width: #{map-deep-get($bs-xl, sidebar, closed, width)}px;
                color: map-deep-get($bs-color, grey);
                background-color: map-deep-get($bs-color, sidebar, greyDark);

                .v-btn {
                    width: #{map-deep-get($bs-xl, sidebar, closed, width)}px;
                    min-width: 0; // überschreiben von vuetify
                    height: #{math.div(map-deep-get($bs-xl, sidebar,closed,width) * 3, 4)}px;
                    margin: 0; // überschreiben von vuetify
                    padding: 0; // überschreiben von vuetify
                    color: white;

                    &--disabled .v-icon {
                        color: map-deep-get($bs-color, greyDark) !important;
                    }

                    &.pullicon {
                        flex-grow: 1; // height "calc(100vh - %s)" % xl.header.height
                        width: #{map-deep-get($bs-xl, sidebar, closed, width)}px;
                    }

                    &.pinicon {
                        &.pinned {
                            background-color: lighten(map-deep-get($bs-color, sidebar, greyDark), 15%);

                            .v-btn__content {
                                overflow: hidden;
                            }

                            .v-icon {
                                position: relative;
                                top: 8px;
                            }
                        }
                    }

                    &:focus {
                        color: white;
                    }
                }

                .v-divider {
                    background-color: map-deep-get($bs-color, snackbar, info);
                }

                .share-bar .v-btn {
                    color: white;
                }
            }

            &-content {
                width: calc(var(--bs-sidebar-width) - #{map-deep-get($bs-xl, sidebar, closed, width)}px);
                padding: #{map-deep-get($bs-xl, sidebar, padding)}px;
                overflow: hidden;
                color: map-deep-get($bs-color, sidebar, white);
                background-color: map-deep-get($bs-color, sidebar, background);
                visibility: hidden;

                h3 {
                    font-weight: 500;
                    font-size: 20px;
                }

                p, h3 {
                    padding: 0 #{map-deep-get($bs-xl, sidebar, content, paddingX)}px;
                }
            }

            &-share {
                margin-bottom: #{math.div(map-deep-get($bs-xl, sidebar,padding), 2)}px;
                background-color: map-deep-get($bs-color, sidebar, greyDark);

                .v-btn {
                    width: #{map-deep-get($bs-xl, sidebar, iconWidth)}px;
                    height: #{map-deep-get($bs-xl, sidebar, iconWidth)}px;
                    margin: #{math.div(map-deep-get($bs-xl, sidebar,padding) * 3, 2)}px 0;

                    &--disabled .v-icon {
                        color: map-deep-get($bs-color, greyDark) !important;
                    }
                }

                .aside-title {
                    display: flex;
                    padding-top: #{map-deep-get($bs-xl, sidebar, content, paddingX)}px;
                }

                .help-btn {
                    flex-grow: 0 !important; // Überschreiben von d-flex im child
                }
            }

            &.main-header {
                &--unpinned {
                    padding-top: 0;
                }

                &--pinned, &--top {
                    padding-top: map-deep-get($bs-xl, header, height);

                    @media (max-width: #{map-deep-get($bs-md, breakpointValue)}px) {
                        padding-top: map-deep-get($bs-md, header, height);
                    }
                }
            }

            &--banner-visible {
                padding-top: #{map-deep-get($bs-xl, banner,height) + map-deep-get($bs-xl, header, height)};

                @media (max-width: #{map-deep-get($bs-md, breakpointValue)}px) {
                    padding-top: #{map-deep-get($bs-xl, banner,height) + map-deep-get($bs-md, header, height)};
                }

                &.main-header {
                    &--unpinned {
                        padding-top: map-deep-get($bs-xl, banner, height);
                    }

                    &--pinned, &--top {
                        padding-top: #{map-deep-get($bs-xl, banner,height) + map-deep-get($bs-xl, header, height)};

                        @media (max-width: #{map-deep-get($bs-md, breakpointValue)}px) {
                            padding-top: #{map-deep-get($bs-xl, banner,height) + map-deep-get($bs-md, header, height)};
                        }
                    }
                }
            }
        }

        &.open {
            width: var(--bs-sidebar-width);

            .aside-info-pullbar {
                width: #{math.div(map-deep-get($bs-xl, sidebar,closed,width), 2)}px;

                .v-btn {
                    width: #{math.div(map-deep-get($bs-xl, sidebar,closed,width), 2)}px;

                    &.pullicon {
                        width: #{math.div(map-deep-get($bs-xl, sidebar,closed,width), 2)}px;

                        .v-icon {
                            transform: rotate(180deg);
                        }
                    }
                }
            }

            .aside-info-content {
                width: calc(var(--bs-sidebar-width) - #{math.div(map-deep-get($bs-xl, sidebar, closed, width), 2)}px);
                overflow-y: scroll;
                visibility: visible;
            }
        }
    }

    /* rtl:begin:ignore */

    &[dir="rtl"] {
        .aside-info-wrapper {
            .aside-info-pullbar {
                .v-btn.pullicon .v-icon {
                    transform: rotate(180deg);
                }
            }

            &.open {
                .aside-info-pullbar {
                    .v-btn.pullicon .v-icon {
                        transform: rotate(0deg);
                    }
                }
            }
        }
    }

    /* rtl:end:ignore */
}

@media (max-width: #{map-deep-get($bs-xs, breakpointValue)}px) {
    .bs-app {
        .aside-info-wrapper {
            position: fixed;
            top: calc(100% - #{map-deep-get($bs-xs, sidebar,closed,height)}px);
            width: 100%;
            height: 100%;

            .aside-info {
                position: relative;
                flex-direction: column;
                padding-top: 0 !important;

                &-pullbar {
                    flex-direction: row;
                    width: 100%;
                    min-height: #{map-deep-get($bs-xs, sidebar,closed,height)}px;

                    .v-btn {
                        &.pullicon {
                            width: 100%;
                            height: #{map-deep-get($bs-xs, sidebar,closed,height)}px;
                            background: map-deep-get($bs-color, sidebar, greyDark);

                            .v-icon {
                                transform: rotate(90deg);
                            }

                            &.animate {
                                @keyframes slide {
                                    0% {
                                        background: map-deep-get($bs-color, sidebar, greyDark);
                                    }

                                    25% {
                                        background: map-deep-get($bs-color, bsHighlight);
                                    }

                                    50% {
                                        background: map-deep-get($bs-color, sidebar, greyDark);
                                    }

                                    100% {
                                        background: map-deep-get($bs-color, bsHighlight);
                                    }
                                }

                                background: map-deep-get($bs-color, bsHighlight);
                                animation: slide .8s linear;
                            }

                            &:not(.animate) {
                                transition: background 300ms;
                            }
                        }
                    }
                }

                &-content {
                    width: 100%;
                    height: calc(100vh - #{map-deep-get($bs-xs, sidebar,closed,height) - map-deep-get($bs-xs, header, height)});
                }

                &--banner-visible {
                    padding-top: 0 !important;

                    .aside-info {
                        &-pullbar {
                            .v-btn {
                                &.pullicon {
                                    height: #{map-deep-get($bs-xs, sidebar,closed,height)}px;
                                }
                            }
                        }

                        &-content {
                            height: calc(100vh - #{map-deep-get($bs-xs, sidebar,closed,height) - map-deep-get($bs-xs, header, height) - map-deep-get($bs-xs, banner, height)});
                        }
                    }
                }
            }

            &.open {
                width: 100vw;

                .aside-info {
                    &-content {
                        width: 100%;
                        padding: 8px;
                    }

                    &-pullbar {
                        width: 100%;

                        .pullicon {
                            background: map-deep-get($bs-color, sidebar, greyDark);

                            .v-icon {
                                transform: rotate(270deg) !important;
                            }
                        }
                    }
                }
            }
        }

        &.audio-player .aside-info-wrapper {
            bottom: #{map-deep-get($bs-xl, audioPlayer, height)}px;

            .aside-info-pullbar .v-btn.pullicon {
                height: #{map-deep-get($bs-xs, sidebar, closed, height)}px;
            }
        }

        /* rtl:begin:ignore */

        &[dir="rtl"] {
            .aside-info-wrapper {
                .aside-info-pullbar {
                    .v-btn.pullicon .v-icon {
                        transform: rotate(90deg);
                    }
                }

                &.open {
                    .aside-info-pullbar {
                        .v-btn.pullicon .v-icon {
                            transform: rotate(-90deg) !important;
                        }
                    }
                }
            }
        }

        /* rtl:end:ignore */
    }
}
</style>
