import {animateScroll,} from '@/assets/js/src/util/uiTools'
import {getCurrentInstance, onMounted, onUnmounted,} from 'vue'
import {getActivePinia, storeToRefs,} from 'pinia'
import {useAppUiStore,} from '@/assets/js/src/pinia/appUi'
import {useComponentIsActive,} from '@/assets/js/src/util/composables/useComponentIsActive'
import {useAutocompleteStore,} from '@/assets/js/src/modules/autocomplete/_pinia/autocomplete'

export function useSpaceScroll () {
    let {proxy,} = getCurrentInstance()
    let activePinia = getActivePinia()
    let appUiStore = useAppUiStore(activePinia)
    let autocompleteStore = useAutocompleteStore(activePinia)
    let {banner,audioPlayer,overlay,menu,} = storeToRefs(appUiStore)
    let {componentIsActive,} = useComponentIsActive()
    
    const handleSpaceScroll = function (event) {
        let curElement = document.activeElement?.nodeName
        let scrollHeaderOffset = 60			// screen height - this offset value = scroll distance
        let doc = document.documentElement

        if ((event.keyCode === 32 && curElement === "BODY") || (event.keyCode === 33 || event.keyCode === 34)) {

            event.preventDefault()
            let header = document.querySelector('.main-header')
            let stickyHeaderHeight = 0
            if(header && header.offsetTop >= 0) {
                stickyHeaderHeight = header.clientHeight
            }
            // Banner berücksichtigen
            if(banner.value) {
                stickyHeaderHeight += document.querySelector('.banner-wrapper__banner')?.clientHeight ?? 0
            }
            // Audio Player berücksichtigen
            if(audioPlayer.value) {
                stickyHeaderHeight += document.querySelector('.audio-player-wrapper')?.clientHeight ?? 0
            }
            let newViewportHeight = window.innerHeight - stickyHeaderHeight - scrollHeaderOffset

            let isShift = !!(event.shiftKey || event.keyCode === 33)
            let currScrollPosition = (window.pageYOffset || doc.scrollTop) - (doc.clientTop || 0)

            isShift ? animateScroll(currScrollPosition - newViewportHeight) : animateScroll(newViewportHeight + currScrollPosition)
        }
    }

    const handleKeyDownSpaceScroll = function (event) {
        if (componentIsActive && 
            !overlay && 
            !menu && 
            !autocompleteStore.isComponentVisible && 
            event.target.tagName !== 'INPUT' && 
            event.target.tagName !== 'TEXTAREA' && 
            !(event.target.classList || {contains:()=>false,}).contains('ck-content')) {
            handleSpaceScroll(event)
        }
    } 
    onMounted(()=>{
        !proxy.$vuetify.display.smAndDown && document.addEventListener('keydown', handleKeyDownSpaceScroll)
    })
    
    onUnmounted(()=>{
        document.removeEventListener('keydown', handleKeyDownSpaceScroll)
    })

    return {}
}
